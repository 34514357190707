import http from "../../http-common";

class OtherService {
    createDataSystem() {
        return http.post(`/common/create/common`);
    }
    createFolderSystem() {
        return http.post(`/common/create/folder-system`);
    }
    checkBackend() {
        return http.get(`/test/check-backend`);
    }
    createAttendanceEvaluateRootFromDate(type, date, idSchool) {
        if (type == "attendanceType") {
            return http.post(`/common/create/attendance?idSchool=${idSchool}`, date);
        } else if (type == "evaluateType") {
            return http.post(`/common/create/evaluate?idSchool=${idSchool}`, date);
        }
    }

    createEvaluateWeekMonthRootFromDate(type, date, idSchool) {

        if (type == "evaludateWeek") {
            return http.post(`/common/create/evaluate-week?idSchool=${idSchool}`, date);
        } else if (type == "evaludateMonth") {
            return http.post(`/common/create/evaluate-month?idSchool=${idSchool}`, date);
        }
    }
    getSchoolAllService() {
        return http.get(`/school/all`);
    }
    getAttendanceDuplicateService(date) {
        return http.get(`/common/attendance/duplicate?date=${date}`);
    }
    deleteAttendanceDuplicateService(date) {
        return http.delete(`/common/attendance/duplicate?date=${date}`);
    }
    getEvaluateDuplicateService(date) {
        return http.get(`/common/evaluate/duplicate?date=${date}`);
    }
    deleteEvaluateDuplicateService(date) {
        return http.delete(`/common/evaluate/duplicate?date=${date}`);
    }


    // tạo dữ liệu chung cho supper plus Phạm Cường
    createAttendanceEvaluateRootFromDateForSupperPlus(type, date) {
        if (type === "attendanceType") {
            return http.post(`/supperPlus/create/attendance`, date);
        } else if (type === "evaluateType") {
            return http.post(`/supperPlus/create/evaluate`, date);
        }
    }

    createEvaluateWeekMonthRootFromDateForSupperPlus(type, date) {
        if (type === "evaludateWeek") {
            return http.post(`/supperPlus/create/evaluate-week`, date);
        } else if (type === "evaludateMonth") {
            return http.post(`/supperPlus/create/evaluate-month`, date);
        }
    }

    getAttendanceDuplicateServiceForSupperPlus(date) {
        return http.get(`/supperPlus/attendance/duplicate?date=${date}`);
    }
    deleteAttendanceDuplicateServiceForSupperPlus(date) {
        return http.delete(`/supperPlus/attendance/duplicate?date=${date}`);
    }

    getEvaluateDuplicateServiceForSupperPlus(date) {
        return http.get(`/supperPlus/evaluate/duplicate?date=${date}`);
    }
    deleteEvaluateDuplicateServiceForSupperPlus(date) {
        return http.delete(`/supperPlus/evaluate/duplicate?date=${date}`);
    }

}

export default new OtherService();